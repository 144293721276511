<template>
  <Toast />
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">Reportes</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer"
        >Reporte Ventas</a
      >
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active"
        >Reporte de Ventas detalladas por Caja</span
      >
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row"
    >
      <h4><strong>REPORTE DE VENTAS DETALLE POR CAJA</strong></h4>
    </div>
    <div class="col-12">
      <div class="card p-fondo">
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-3">
            <label for="sucursal"><strong>SUCURSAL: </strong></label>
            <Dropdown
              v-model="sucursalSelected"
              :options="sucursales"
              optionLabel="nombre"
              optionValue="id"
            >
            </Dropdown>
          </div>
          <!-- <div class="field col-12 md:col-2">
            <label for="id_caja"><strong>CÓDIGO CAJA: </strong></label>
            <InputNumber
              v-model="codigoCaja"
              placeholder="Código Caja"
              :inputClass="'text-right'"
              :min="0"
              @keydown.enter="buscar_reporte"
            />
          </div> -->
          <div class="field col-12 md:col-3">
            <label for="rango_fecha"
              ><strong>SELECCIONE RANGO DE FECHA:</strong></label
            >
            <Calendar
              v-model="fechas"
              selectionMode="range"
              :manualInput="false"
              dateFormat="dd/mm/yy"
            />
          </div>
          <div class="field md:col-4">
            <label for="usuario" class="mb-1"><strong>USUARIO: </strong></label>
            <!-- <Listbox
              v-model="usuarioSeleccionado"
              :options="usuarios"
              optionLabel="name"
            /> -->
            <Dropdown
              v-model="usuarioSeleccionado"
              :options="usuarios"
              optionLabel="name"
            />
          </div>
          <div class="field col-12 md:col-2">
            <label for="buscar_reporte"><strong>ACCIONES </strong></label>

            <Button
              label="APLICAR FILTROS"
              icon="pi pi-search"
              class="p-button-primary mr-2"
              :loading="enviando"
              :disabled="enviando"
              @click="buscar_reporte(null)"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" v-if="ventasDetalle.length > 0">
      <div class="flex flex-wrap justify-content-start gap-2">
        <strong
          style="
            font-size: 1.2rem;
            padding: 0.5rem;
            border-radius: 0.5rem;
            color: #000000;
            font-weight: bold;
          "
          >CAJERO : {{ nombre_cajero }}
        </strong>
        <strong
          style="
            font-size: 1.2rem;
            padding: 0.5rem;
            border-radius: 0.5rem;
            color: #000000;
            font-weight: bold;
          "
          >FECHA DE VENTA:</strong
        >
        <span
          class="text-900"
          style="
            font-size: 1.2rem;
            background-color: #6ccfe8;
            padding: 0.5rem;
            border-radius: 0.5rem;
            color: #fff;
            font-weight: bold;
          "
          >{{ fecha_busqueda }}</span
        >
        <Button
          @click="previousDay"
          icon="pi pi-angle-left"
          label="Anterior"
          :disabled="verificarDiaAnteriorButton()"
          class="p-button-rounded p-button-text p-button-plain p-button-lg text-900"
          v-tooltip.top="'Día anterior'"
        />
        <Button
          @click="nextDay"
          :disabled="verificarUltimoDiaDeBusqueda()"
          icon="pi pi-angle-right"
          label="Siguiente"
          class="p-button-rounded p-button-text p-button-plain p-button-lg text-900"
          v-tooltip.top="'Día siguiente'"
        />
        <Button
              class="p-button-info"
              text
              icon="pi pi-bars"
              label="VER VENTAS"
              @click="listar_ventas"
              v-if="ver_lista"
            />
      </div>

      <!-- <div class="flex flex-wrap justify-content-start gap-2">
        <label for=""
          ><strong>FECHA DE VENTA: </strong>
          <span
            class="text-900"
            style="
              font-size: 1.2rem;
              background-color: #6ccfe8;
              padding: 0.5rem;
              border-radius: 0.5rem;
              color: #fff;
              font-weight: bold;
            "
            >{{ fecha_busqueda }}</span
          >
        </label>
        <Button
          @click="previousDay"
          icon="pi pi-angle-left"
          label="Anterior"
          :disabled="verificarDiaAnteriorButton()"
          class="p-button-rounded p-button-text p-button-plain p-button-lg"
          v-tooltip.top="'Día anterior'"
        />
        <Button
          @click="nextDay"
          :disabled="verificarUltimoDiaDeBusqueda()"
          icon="pi pi-angle-right"
          label="Siguiente"
          class="p-button-rounded p-button-text p-button-plain p-button-lg"
          v-tooltip.top="'Día siguiente'"
        />
      </div> -->
    </div>
    <div class="col-12">
      
      <DataTable
        ref="reporte_ventas_caja_detalle_productos"
        class="p-datatable-sm text-center"
        responsive="true"
        stripedRows
        showGridlines
        :value="listado_productos"
        v-if="ver_lista"
      >
        <Column field="id" header="CODIGO"></Column>
        <Column
          field="descripcion"
          header="PRODUCTO/DESCRIPCIÓN"
          style="font-weight: bold"
        ></Column>
        <Column
          header="CANTIDAD"
          field="cantidad"
          class="text-right bg-blue-100"
        ></Column>
        <Column
          header="PRECIO"
          field="precio"
          class="text-right bg-blue-100"
        ></Column>
        <Column
          header="SUBTOTAL"
          field="subtotal"
          class="text-right bg-blue-100"
        ></Column>
        <template #footer>
          <div class="flex flex-wrap justify-content-end gap-2">
            <h3 for="TOTALES">TOTAL PRODUCTOS : {{ convertirNumeroGermanicFormat(listado_total_productos) }}</h3>
          </div>
        </template>
      </DataTable>
      <DataTable
        ref="reporte_ventas_caja"
        class="p-datatable-sm text-center"
        responsive="true"
        stripedRows
        showGridlines
        :value="ventasDetalle"
        :loading="enviando"
        v-model:expandedRows="expandedRows"
        v-if="!ver_lista"
      >
        <template #header>
          <div class="flex flex-wrap justify-content-end gap-2">
            <Button
              class="p-button-success"
              text
              icon="pi pi-bars"
              label="LISTA PRODUCTOS"
              @click="listar_productos"
            />

            <!-- <Button
              class="p-button-danger"
              text
              icon="pi pi-file-pdf"
              label="Exportar PDF"
              @click="exportPdf"
              v-show="false"
            /> -->
            <Button
              text
              icon="pi pi-plus"
              label="Expandir Todo"
              @click="expandAll"
            />
            <Button
              text
              icon="pi pi-minus"
              label="Contraer Todo"
              @click="collapseAll"
            />
          </div>
        </template>
        <Column expander style="width: 5rem" />
        <Column header="NRO. VENTA">
          <template #body="slotProps">
            <div>
              {{ slotProps.data.id }}
            </div>
          </template>
        </Column>
        <Column header="RAZON SOCIAL">
          <template #body="slotProps">
            <div>
              {{ slotProps.data.razon_social }}
            </div>
          </template>
        </Column>
        <Column header="FECHA">
          <template #body="slotProps">
            <div>
              {{ slotProps.data.fecha_venta }}
            </div>
          </template>
        </Column>
        <Column header="FACTURADO">
          <template #body="slotProps">
            <div>
              {{ slotProps.data.facturado ? "SI" : "NO" }}
            </div>
          </template>
        </Column>
        <Column header="ESTADO">
          <template #body="slotProps">
            <div>
              {{ slotProps.data.estado_texto }}
            </div>
          </template>
        </Column>
        <Column header="METODO DE PAGO">
          <template #body="slotProps">
            <div>
              {{ slotProps.data.tipo_pago_nombre }}
            </div>
          </template>
        </Column>
        <Column header="FORMA DE PAGO">
          <template #body="slotProps">
            <div>
              {{ slotProps.data.metodo_pago_nombre }}
            </div>
          </template>
        </Column>
        <Column header="TOTAL" class="text-right bg-blue-200">
          <template #body="slotProps">
            <div>
              <strong>{{ slotProps.data.total_cancelar }}</strong>
            </div>
          </template>
        </Column>
        <template #expansion="slotProps">
          <div class="p-3">
            <h5>
              DETALLE VENTA: <strong>{{ slotProps.data.id }}</strong>
            </h5>
            <DataTable :value="slotProps.data.productos">
              <Column field="id" header="CODIGO"></Column>
              <Column
                field="descripcion"
                header="PRODUCTO/DESCRIPCIÓN"
                style="font-weight: bold"
              ></Column>
              <Column header="CANTIDAD" class="text-right bg-blue-100">
                <template #body="slotProps">
                  {{ slotProps.data.pivot.cantidad }}
                </template>
              </Column>
              <Column header="PRECIO" class="text-right">
                <template #body="slotProps">
                  {{ slotProps.data.pivot.precio }}
                </template>
              </Column>
              <Column header="SUBTOTAL" class="text-right">
                <template #body="slotProps">
                  {{
                    slotProps.data.pivot.cantidad * slotProps.data.pivot.precio
                  }}
                </template>
              </Column>
            </DataTable>
          </div>
        </template>
        <template #loading
          ><div class="flex align-items-center justify-content-center">
            <ProgressSpinner /></div
        ></template>
        <template #empty>
          <div class="p-d-flex p-ai-center p-jc-center" style="height: 100px">
            <span class="p-text-center"
              >No se encontraron ventas para los filtros seleccionados</span
            >
          </div>
        </template>
        <template #footer>
          <div class="flex flex-wrap justify-content-end gap-2">
            <h3 for="TOTALES">TOTAL VENTAS : {{ convertirNumeroGermanicFormat(total_ventas) }}</h3>
          </div>
        </template>
      </DataTable>
    </div>
  </div>
</template>

<script>
import SucursalService from "@/service/SucursalService";
import CajaService from "@/service/CajaService";
import UserService from "@/service/UserService";

export default {
  data() {
    return {
      sucursalSelected: 1,
      sucursales: [],
      codigoCaja: null,
      enviando: false,
      ventasDetalle: [],
      expandedRows: [],
      caja: {},
      fechas: [new Date(), new Date()],
      usuarioSeleccionado: null,
      usuarios: [],
      usuarios_ventas: [],
      total_ventas: 0,
      fecha_busqueda: null,
      nombre_cajero: null,
      listado_productos: [],
      ver_lista: false,
      listado_total_productos: 0,
    };
  },
  sucursalService: null,
  cajaService: null,
  userService: null,
  created() {
    this.sucursalService = new SucursalService();
    this.cajaService = new CajaService();
    this.userService = new UserService();
  },
  mounted() {
    this.cargarSucursales();
    this.cargarUsuarios();
  },
  methods: {
    listar_ventas() {
      this.ver_lista = false;
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(numero);
    },
    listar_productos() {
      this.ver_lista = true;

      this.listado_productos = [];
      this.listado_total_productos = 0;
      this.ventasDetalle.forEach((venta) => {
        venta.productos.forEach((producto) => {
          let producto_tmp = {
            id: producto.id,
            descripcion: producto.descripcion,
            cantidad: parseInt(producto.pivot.cantidad),
            precio: parseInt(producto.pivot.precio),
            subtotal: parseInt(producto.pivot.cantidad * producto.pivot.precio),
          };
          
          //Verificar si el producto ya esta en el listado total de productos
          let producto_encontrado = this.listado_productos.find(
            (p) => p.id == producto.id
          );

          if (producto_encontrado != null && producto_encontrado != undefined) {
            producto_encontrado.cantidad += parseInt(producto.pivot.cantidad);
            producto_encontrado.subtotal +=
            parseInt(producto.pivot.cantidad * producto.pivot.precio);
            this.listado_total_productos += parseInt(producto.pivot.cantidad * producto.pivot.precio);
            return;
          }
          this.listado_total_productos += parseInt(producto.pivot.cantidad * producto.pivot.precio);
          this.listado_productos.push(producto_tmp);
        });
      });

      console.log(this.ver_lista, "ver_lista");
    },
    verificarUltimoDiaDeBusqueda() {
      let resultado = false;
      if (this.fechas[1] != null && this.fechas[1] != undefined) {
        let fecha_fin = this.fechas[1];
        let fecha_fin_tmp =
          fecha_fin.getFullYear() +
          "-" +
          (fecha_fin.getMonth() + 1) +
          "-" +
          fecha_fin.getDate();
        if (
          new Date(this.fecha_busqueda + "T00:00:00").getTime() ==
          new Date(fecha_fin_tmp + "T00:00:00").getTime()
        ) {
          resultado = true;
        }
      }
      return resultado;
    },
    verificarDiaAnteriorButton() {
      let resultado = false;
      if (this.fechas[0] != null && this.fechas[0] != undefined) {
        let fecha_inicio = this.fechas[0];
        let fecha_inicio_tmp =
          fecha_inicio.getFullYear() +
          "-" +
          (fecha_inicio.getMonth() + 1) +
          "-" +
          fecha_inicio.getDate();
        if (
          new Date(this.fecha_busqueda + "T00:00:00").getTime() ==
          new Date(fecha_inicio_tmp + "T00:00:00").getTime()
        ) {
          resultado = true;
        }
      }
      return resultado;
    },
    VerificarFiltroUltimoDia() {
      let resultado = false;

      let fecha_fin = this.fechas[1];
      let fecha_fin_tmp =
        fecha_fin.getFullYear() +
        "-" +
        (fecha_fin.getMonth() + 1) +
        "-" +
        fecha_fin.getDate();
      if (
        new Date(this.fecha_busqueda + "T00:00:00").getTime() ==
        new Date(fecha_fin_tmp + "T00:00:00").getTime()
      ) {
        resultado = true;
      }
      return resultado;
    },
    nextDay() {
      let x = this.fecha_busqueda + "T00:00:00";
      let fecha_tmp = new Date(x);

      fecha_tmp.setDate(fecha_tmp.getDate() + 1);

      this.buscar_reporte(fecha_tmp);
    },
    previousDay() {
      let fecha_tmp = new Date(this.fecha_busqueda + "T00:00:00");
      fecha_tmp.setDate(fecha_tmp.getDate() - 1);

      /* this.fechas = [fechaInicioNueva,fechaInicioNueva]; */
      this.buscar_reporte(fecha_tmp);
    },
    cargarUsuarios() {
      this.userService
        .getUsersAll()
        .then((res) => {
          this.usuarios = res;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    buscar_reporte(fecha_tmp = null) {
      //Formatear fecha a yyyy-mm-dd
      if (fecha_tmp == null) {
        this.fecha_busqueda = null;
        this.total_ventas = 0;
        this.listado_productos = [];
        this.ver_lista = false;
      }
      if (
        this.usuarioSeleccionado == null ||
        this.usuarioSeleccionado == undefined
      ) {
        this.$toast.add({
          severity: "warn",
          summary: "Advertencia",
          detail: "Debe seleccionar un usuario",
          life: 3000,
        });
        return;
      }
      let fechaInicio;
      if (this.fecha_busqueda != null) {
        fechaInicio =
          fecha_tmp.getFullYear() +
          "-" +
          (fecha_tmp.getMonth() + 1) +
          "-" +
          fecha_tmp.getDate();
        this.fecha_busqueda = fechaInicio;
      } else {
        fechaInicio =
          this.fechas[0].getFullYear() +
          "-" +
          (this.fechas[0].getMonth() + 1) +
          "-" +
          this.fechas[0].getDate();
      }
      let fechaFin =
        this.fechas[1].getFullYear() +
        "-" +
        (this.fechas[1].getMonth() + 1) +
        "-" +
        this.fechas[1].getDate();

      this.ventasDetalle = [];
      this.enviando = true;
      let request = {
        idSucursal: this.sucursalSelected,
        fecha_inicio: fechaInicio,
        fecha_fin: fechaFin,
        id_usuario: this.usuarioSeleccionado.id,
      };
      this.cajaService
        .buscarCajaVentas(request)
        .then((res) => {
          this.enviando = false;
          this.fecha_busqueda = res.fecha_busqueda;
          this.total_ventas = 0;
          this.listado_productos = [];
          this.ver_lista = false;
          if (res.status == 200 && res.ventas.length > 0) {
            this.ventasDetalle = res.ventas;
            this.nombre_cajero = this.usuarioSeleccionado.name;

            res.ventas.forEach((venta) => {
              this.total_ventas += parseInt(venta.total_cancelar);
            });
          } else {
            if (this.VerificarFiltroUltimoDia()) {
              this.$toast.add({
                severity: "warn",
                summary: "Advertencia",
                detail:
                  "No se encontraron ventas para los filtros seleccionados",
                life: 3000,
              });
            } else {
              if (this.fecha_busqueda == fechaFin) {
                return false;
              }
              this.nextDay();
            }
          }
          /* if (
            res.ventas == null ||
            res.ventas == undefined ||
            res.status == 400
          ) {
            this.$toast.add({
              severity: "warn",
              summary: "Advertencia",
              detail: "No se encontraron ventas para los filtros seleccionados",
              life: 3000,
            });
            return;
          } */
          //this.ventasDetalle = res.ventas;
        })
        .catch((err) => {
          this.enviando = false;

          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.mensaje,
            life: 3000,
          });
          console.log(err);
        });
    },
    cargarSucursales() {
      this.sucursalService
        .getSucursalesAll()
        .then((res) => {
          res.forEach((sucursal) => {
            this.sucursales.push(sucursal);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    expandAll() {
      this.expandedRows = this.ventasDetalle.filter((p) => p.id);
    },
    collapseAll() {
      this.expandedRows = null;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
.p-fondo {
  background-color: #7bffae;
}
</style>
